<template>
  <v-col class="px-0 py-0">
    <loader v-if="showLoader" />
    <v-col class="px-0 py-0" v-else>
      <success-snackbar
        v-if="showSuccessSnackbar"
        snackbarText="Ваші дані успішно оновлені"
      />
      <h3 class="pageName">{{ "personal_data_label" | localize }}</h3>
      <div class="profileSectionBackground" style="padding-bottom: 0px">
        <span>{{ "private_data_label" | localize }}</span>
        <div class="sectionLine" style="margin: 20px 0px" />
        <v-col class="px-0 py-0">
          <v-row no-gutters align="center">
            <v-text-field
              dense
              outlined
              :placeholder="'type_here' | localize"
              label="Ім'я"
              color="#144FA9"
              v-model="user.first_name"
              :style="
                $vuetify.breakpoint.smAndDown ? '' : 'padding-right: 15px'
              "
              class="rounded-lg"
              :error-messages="firstNameError"
              @blur="$v.user.first_name.$touch()"
              height="48px"
            >
              <template v-slot:label>
                {{ "first_name_label" | localize }}&nbsp;<span
                  class="requireColor"
                  >*</span
                >
              </template>
            </v-text-field>
            <v-text-field
              dense
              outlined
              :placeholder="'type_here' | localize"
              label="Прізвище"
              color="#144FA9"
              v-model="user.last_name"
              :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 15px'"
              class="rounded-lg"
              :error-messages="lastNameError"
              @blur="$v.user.last_name.$touch()"
              height="48px"
            >
              <template v-slot:label>
                {{ "last_name_label" | localize }}&nbsp;<span
                  class="requireColor"
                  >*</span
                >
              </template>
            </v-text-field>
          </v-row>
          <v-row no-gutters align="center">
            <v-text-field
              dense
              outlined
              :placeholder="'type_here' | localize"
              label="Номер телефону"
              v-mask="'+##(###) ###-##-##'"
              color="#144FA9"
              v-model="user.phone_number"
              :style="
                $vuetify.breakpoint.smAndDown ? '' : 'padding-right: 15px'
              "
              class="rounded-lg"
              :error-messages="phoneNumberError"
              @blur="$v.user.phone_number.$touch()"
              height="48px"
            >
              <template v-slot:label>
                {{ "phone_number_label" | localize }}&nbsp;<span
                  class="requireColor"
                  >*</span
                >
              </template>
            </v-text-field>
            <v-text-field
              dense
              outlined
              :placeholder="'type_here' | localize"
              label="Email"
              color="#144FA9"
              v-model="user.email"
              :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 15px'"
              class="rounded-lg"
              :error-messages="emailError"
              @blur="$v.user.email.$touch()"
              height="48px"
            >
              <template v-slot:label>
                Email&nbsp;<span class="requireColor">*</span>
              </template>
            </v-text-field>
          </v-row>
        </v-col>
      </div>
      <div class="profileSectionBackground">
        <span>{{ "password_label" | localize }}</span>
        <div class="sectionLine" style="margin: 20px 0px" />
        <v-row no-gutters align="start">
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" class="px-0 py-0">
            <v-text-field
              outlined
              dense
              color="#164B78"
              :placeholder="'type_here' | localize"
              label="Старий пароль"
              class="rounded-lg"
              height="48px"
              v-model="password.current_password"
              :type="showCurrentPassword ? 'text' : 'password'"
              :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :hide-details="!isChangePassword && $vuetify.breakpoint.smAndDown"
              @click:append="showCurrentPassword = !showCurrentPassword"
              :error-messages="
                currentPasswordError.length
                  ? currentPasswordError
                  : serverPasswordError
              "
              @input="serverPasswordError = []"
            >
              <template v-slot:label>
                {{ "current_password_label" | localize
                }}<span class="requireColor">*</span>
              </template>
            </v-text-field>
            <v-expand-transition>
              <v-col class="px-0 py-0" v-if="isChangePassword">
                <v-tooltip bottom color="rgba(0, 0, 0, 0.8)" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      color="#164B78"
                      :placeholder="'type_here' | localize"
                      label="Новий пароль"
                      class="rounded-lg"
                      v-model="password.password"
                      height="48px"
                      :type="showNewPassword ? 'text' : 'password'"
                      :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showNewPassword = !showNewPassword"
                      :error-messages="passwordError"
                    >
                      <template v-slot:label>
                        {{ "new_password_label" | localize
                        }}<span class="requireColor">*</span>
                      </template>
                    </v-text-field>
                  </template>
                  {{ "password_hint" | localize }}
                </v-tooltip>
                <v-text-field
                  outlined
                  dense
                  color="#164B78"
                  :placeholder="'type_here' | localize"
                  label="Повторіть пароль"
                  class="rounded-lg"
                  v-model="password.confirm_password"
                  height="48px"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                  :error-messages="confirmPasswordError"
                >
                  <template v-slot:label>
                    {{ "confirm_password_label" | localize
                    }}<span class="requireColor">*</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-expand-transition>
          </v-col>
          <submit-button
            style="width: 180px; height: 48px !important; margin-left: 20px"
            @click="isChangePassword = true"
            v-if="!isChangePassword && !$vuetify.breakpoint.smAndDown"
            >{{ "change_password_label" | localize }}</submit-button
          >
        </v-row>
        <v-row
          no-gutters
          justify="center"
          v-if="!isChangePassword && $vuetify.breakpoint.smAndDown"
          style="margin-top: 12px"
        >
          <span
            class="changePasswordText"
            style="color: #144fa9"
            @click="isChangePassword = true"
            >{{ "change_password_label" | localize }}</span
          >
        </v-row>
      </div>
      <submit-button
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'margin-top: 20px'
            : 'margin: 0px 16px !important'
        "
        @click="updateUserData"
        >{{ "save_btn" | localize }}</submit-button
      >
      <v-row no-gutters justify="center">
        <cancel-btn
          text="Скасувати"
          @click="(showLoader = true), getUserProfile()"
        />
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  email,
  required,
  minLength,
  sameAs,
  maxLength,
} from "vuelidate/lib/validators";
import profileService from "@/requests/User/profileService";
import { mapActions, mapGetters } from "vuex";
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import successSnackbar from "../../UI/Snackbars/successSnackbar.vue";
import Loader from "../../UI/Loader.vue";
import CancelBtn from "../../UI/Buttons/cancelBtn.vue";
export default {
  components: { successSnackbar, SubmitButton, Loader, CancelBtn },
  mixins: [validationMixin],
  data: () => ({
    user: {},
    password: {},
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    isChangePassword: false,
    showSuccessSnackbar: false,
    showLoader: true,
    serverPasswordError: [],
  }),
  validations: {
    user: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      phone_number: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(23),
      },
      email: {
        required,
        email,
      },
    },
    password: {
      current_password: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
        validUppercase: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          return containsUppercase;
        },
        validLowercase: function (value) {
          const containsLowercase = /[a-z]/.test(value);
          return containsLowercase;
        },
        validNumber: function (value) {
          const containsNumber = /[0-9]/.test(value);
          return containsNumber;
        },
        validSpecial: function (value) {
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return containsSpecial;
        },
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial &&
            minLength
          );
        },
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  mounted() {
    this.getUserProfile();
  },
  methods: {
    ...mapActions(["updateInfoLogged"]),
    async getUserProfile() {
      let response = await profileService.getUserProfile();
      this.user = response.data;
      if (
        this.loggedUserPTrans.first_name !== this.user.first_name ||
        this.loggedUserPTrans.last_name !== this.user.last_name
      ) {
        this.updateInfoLogged({
          email: this.user.email,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          phone_number: this.user.phone_number.replace(/\D+/g, ""),
        });
      }
      this.showLoader = false;
    },
    async updateUserData() {
      this.$v.user.$touch();
      this.showSuccessSnackbar = false;
      if (!this.$v.user.$invalid) {
        let user = new FormData();
        user.append("first_name", this.user.first_name);
        user.append("last_name", this.user.last_name);
        user.append("phone_number", this.user.phone_number.replace(/\D+/g, ""));
        user.append("email", this.user.email);
        await profileService.updateUserProfile(user).then(() => {
          this.updateInfoLogged({
            email: this.user.email,
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            phone_number: this.user.phone_number.replace(/\D+/g, ""),
            token: this.loggedUserPTrans.token,
            role: this.loggedUserPTrans.role,
          });
          if (this.isChangePassword) {
            this.updateUserPassword();
          } else {
            this.showSuccessSnackbar = true;
          }
        });
      }
    },
    async updateUserPassword() {
      this.$v.password.$touch();
      if (!this.$v.password.$invalid) {
        let password = new FormData();
        password.append("old_password", this.password.current_password);
        password.append("password", this.password.password);
        password.append(
          "password_confirmation",
          this.password.confirm_password
        );
        await profileService.updateUserPassword(password).then((res) => {
          if (res.status == "success") {
            this.showSuccessSnackbar = true;
            this.isChangePassword = false;
            this.password = {};
            this.$v.$reset();
          } else if (res.message == "Wrong password") {
            this.serverPasswordError.push(
              "Існуючий пароль введено не правильно"
            );
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
    firstNameError() {
      const errors = [];
      if (!this.$v.user.first_name.$dirty) {
        return errors;
      } else if (!this.$v.user.first_name.required) {
        errors.push("Обов'язкове поле");
      }
      return errors;
    },
    lastNameError() {
      const errors = [];
      if (!this.$v.user.last_name.$dirty) {
        return errors;
      } else if (!this.$v.user.last_name.required) {
        errors.push("Обов'язкове поле");
      }
      return errors;
    },
    phoneNumberError() {
      const errors = [];
      if (!this.$v.user.phone_number.$dirty) {
        return errors;
      } else if (!this.$v.user.phone_number.minLength) {
        errors.push("");
      } else if (!this.$v.user.phone_number.maxLength) {
        errors.push("");
      }

      !this.$v.user.phone_number.required && errors.push("Обов'язкове поле");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      } else if (!this.$v.user.email.required) {
        errors.push("Обов'язкове поле");
      }
      if (!this.$v.user.email.email) {
        errors.push("");
      }
      return errors;
    },
    currentPasswordError() {
      const errors = [];
      if (!this.$v.password.current_password.$dirty) {
        return errors;
      }
      if (!this.$v.password.current_password.required) {
        errors.push("Обов'язкове поле");
        return errors;
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.password.$dirty) {
        return errors;
      }
      if (!this.$v.password.password.required) {
        errors.push("Обов'язкове поле");
        return errors;
      }
      if (
        (!this.$v.password.password.valid &&
          this.$v.password.password.required) ||
        (!this.$v.password.password.minLength &&
          this.$v.password.password.required)
      ) {
        errors.push(
          "Пароль повинен містити мінімум 8 символів, серед яких хоч одну цифру, спецсимвол, велику та маленьку літери"
        );
        return errors;
      }
      return errors;
    },
    confirmPasswordError() {
      const errors = [];
      if (!this.$v.password.confirm_password.$dirty) {
        return errors;
      }
      if (!this.$v.password.confirm_password.sameAsPassword) {
        errors.push("Паролі не збігаються");
        return errors;
      }
      !this.$v.password.confirm_password.required &&
        errors.push("Обов'язкове поле");
      return errors;
    },
  },
};
</script>


<style scoped>
@media only screen and (max-width: 1023px) {
  .profileSectionBackground {
    border-radius: 10px;
    background: #fff;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
@media only screen and (max-width: 1024px) {
  .profileSectionBackground {
    border-radius: 10px;
    background: #fff;
    padding: 16px;
    margin-top: 20px;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.requireColor {
  color: red;
}
</style>
<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 14px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>